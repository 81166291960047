import { Stack } from '@chakra-ui/react'
import { type ReactNode } from 'react'
import { HomepageFooter } from './footer'
import { Header } from './header'
import { Main } from '../../../shared/page/main'
import { Navigation } from './navigation'
import type { HomepageNewsletterType, HomePagePageTheme } from '@twlvxtwlv/types'
import { HomePageThemes } from '@twlvxtwlv/types'
import { useLoading } from '@twlvxtwlv/context'
import Loader from './loader/Loader'

type PageProps = {
  bgColor?: string | undefined
  bgImage?: string | null | undefined
  bgRepeat?: boolean
  children: ReactNode
  pageTheme?: HomePagePageTheme
  newsletterType?: HomepageNewsletterType
}

const HomepagePage = ({
  bgColor = 'white',
  bgImage,
  bgRepeat = false,
  children,
  pageTheme = HomePageThemes.default,
  newsletterType = 'default',
}: PageProps) => {
  const { isLoading } = useLoading()

  return (
    <>
      {isLoading ? <Loader /> : null}
      <Stack
        w="full"
        minH="100vh"
        bg={bgColor ?? undefined}
        bgImage={
          bgColor !== 'none'
            ? undefined
            : bgImage ?? '/images/twelvextwelve.com-background-image.webp'
        }
        bgRepeat={bgRepeat ? 'repeat' : 'no-repeat'}
        bgSize={bgRepeat ? 'auto' : 'cover'}
        spacing={0}
        opacity={!isLoading ? 1 : 0}
      >
        <Header>
          <Navigation isHomePage pageTheme={pageTheme} />
        </Header>
        <Main>{children}</Main>
        <HomepageFooter pageTheme={pageTheme} newsletterType={newsletterType} />
      </Stack>
    </>
  )
}

export default HomepagePage
