import type { FooterSection } from './footer-section'
import { Stack } from '@chakra-ui/react'
import { HomepageFooterEntry } from './homepage-footer-entry'

interface HomepageFooterSectionProps {
  section: FooterSection
  isLastSection: boolean
  onNewsletterModalOpen?: () => void
}

export const HomepageFooterSection = ({
  section,
  isLastSection,
  onNewsletterModalOpen,
}: HomepageFooterSectionProps) => {
  const borderStyle = isLastSection ? 'none' : '1px white solid'

  return (
    <Stack
      color="txtWhite"
      p="2rem"
      borderRight={['none', 'none', 'none', borderStyle, borderStyle]}
      borderBottom={[borderStyle, borderStyle, 'none', 'none', 'none']}
      alignItems="start"
      w="full"
    >
      {section.entries.map((entry) => (
        <HomepageFooterEntry
          key={entry.translationKey}
          entry={entry}
          onNewsletterModalOpen={onNewsletterModalOpen}
        />
      ))}
    </Stack>
  )
}
