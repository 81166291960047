import { useTranslation } from 'next-i18next'
import { Heading, Stack, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../../shared/modals/default-modal'
import { HomepageNewsletterSignupForm } from '../homepage-newsletter-signup-form'
import type { HomepageNewsletterType } from '@twlvxtwlv/types'

const defaultNewsletterId = 18
const songStocksNewsletterId = 44

interface HomepageNewsletterModalProps {
  isOpen: boolean
  onClose: () => void
  onSuccess: (newsletterId: number) => void
  onError: () => void
  newsletterType: HomepageNewsletterType
}

export const HomepageNewsletterModal = ({
  isOpen,
  onClose,
  onSuccess,
  onError,
  newsletterType,
}: HomepageNewsletterModalProps) => {
  const newsletterId =
    newsletterType === 'song-stocks' ? songStocksNewsletterId : defaultNewsletterId

  const { t } = useTranslation('common')

  const handleOnClose = () => {
    onClose()
  }

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleOnClose}
      closeOnOverlayClick
      showCloseButton={true}
      backgroundColor="white"
      size="3xl"
      body={
        <Stack gap={4} fontSize={['xs', 'xs', 'sm']} justifyContent="center" alignItems="center">
          <Heading textAlign={'center'} fontSize={['lg', 'lg', 'xl']}>
            {t('common:newsletter-modal-title')}
          </Heading>
          <Text fontSize="lg" fontWeight="400">
            {t('common:newsletter-modal-text')}
          </Text>
          <HomepageNewsletterSignupForm
            onSuccess={() => onSuccess(newsletterId)}
            onError={onError}
            centerElements
            newsletterId={newsletterId}
          />
        </Stack>
      }
    />
  )
}
