export type UrlQueryParams = {
  campaignId: string | null
  claimId: string | null
  redirectTo: string | null
  origin: string | null
  productId: string | null
  rawParams: string | null
  rawQuery: string
  searchParams: URLSearchParams
}

export type DateTimeFormatOptions = 'short' | 'medium' | 'long' | 'full' | undefined

export type MetaMaskOnboardingStatus =
  | '1-initial'
  | '2-pluginInstalled'
  | '3-walletCreatedOrImported'
  | '4-walletAccessApproved'
  | '5-polygonChainApproved'
  | '6-walletConnectedWithTxt'

export interface MetaMaskOnboardingState {
  step: number
  status: MetaMaskOnboardingStatus
}

export interface SignUpInput {
  email: string
  firstName: string
  lastName: string
  userName: string
  password: string
  repeatPassword: string
}

export interface ForgottenPasswordInput {
  email: string
}

export interface ResetPasswordInput {
  password: string
  confirmPassword: string
}

export interface LoginInput {
  email: string
  password: string
}

export interface AgreementInput {
  tocConsent: boolean
  privacyPolicyConsent: boolean
  agbsConsent: boolean
}

export interface AddressInput {
  firstName: string
  lastName: string
  company?: string
  street: string
  postcode: string
  city: string
  countryCode: string
}

export interface NewsletterOptInInput {
  email: string
  tocConsent: boolean
  productSelected?: Array<string>
}

export interface WhitepaperInput {
  email: string
  tocConsent: boolean
}

export interface NotifyMeOptInInput {
  email: string
  tocConsent: boolean
}

export interface ManualTransferInput {
  fromAddress: string
  toAddress: string
  tokenId: string
  smartContractAddress: string
}

export interface VoucherRedemptionInput {
  voucherCode: string
}

export type ClaimStatus =
  | 'initial'
  | 'not-authorized'
  | 'not-claimable-yet'
  | 'already-claimed'
  | 'success'

export type ClaimState =
  | { status: Extract<ClaimStatus, 'initial'> }
  | {
      status: Exclude<ClaimStatus, 'initial' | 'success'>
      modalContent: { body: string; buttonText: string; redirectUrl: string }
    }
  | {
      status: Extract<ClaimStatus, 'success'>
      modalContent: { title: string; body: string; buttonText: string; redirectUrl: string }
    }

export interface ClaimableStatus {
  isFreeClaim: boolean
  isClaimable: boolean
}

export interface ModalContent {
  headerTranslationKey?: string
  bodyTranslationKey: string
  footerLink?: string
  footerButtonTranslationKey?: string
}

export type AuthAction = 'sign-up' | 'login'

export type AssetType = 'Discord' | 'Metaverse' | 'Media' | 'Track'

export interface Asset {
  uuid: string
  private?: boolean
  locked: boolean
  downloadLink: string
  completeLink: string
  fileName: string
  originalFileName: string | undefined
  url?: string
  type: AssetType
}

interface Artist {
  createdAt: string
  id: string
  name: string
  tfCustomerId: null | string
}

export interface Track {
  artists: Artist[]
  createdAt: string
  dashUrl: string
  encodingId: string
  hlsUrl: string
  id: string
  imageUrl: string
  name: string
}

export interface KeyValue {
  key: string
  value: string
}

export type CustomerRole = 'collector' | 'artist' | 'Administrator'

export type ColorType = 'black' | 'white' | 'txtDeutschRapPurple' | 'txtSignalLime'

export type ProductDetailsNavigationKey =
  | 'assets'
  | 'auction'
  | 'crypto-proofs'
  | 'details'
  | 'playlist'
  | 'transactions'
  | 'attributes'
  | 'community'

export type ProductDetailsSlugType =
  | 'assets'
  | 'auction'
  | 'crypto-proofs'
  | 'details'
  | 'playlist'
  | 'transactions'
  | 'attributes'
  | 'community'

export interface ProductDetailsNavigation {
  translationKey: string
  backgroundColor: ColorType
  slug: ProductDetailsSlugType
  isVisible: boolean
}

export type ProductDetailsNavigationListType = Record<
  ProductDetailsNavigationKey,
  ProductDetailsNavigation
>

export type SalesType = 'airdrop' | 'auction' | 'standard' | undefined

export type CampaignDetailsSlugType = 'artist' | 'about' | 'roadmap' | 'token'

export type CookieKeyType = 'LR_COOKIE' | 'TF_COOKIE'

export interface CampaignNavigationRecord {
  backgroundColor: ColorType
  disabled?: boolean
  productId?: string
  salesType?: SalesType
  slug: CampaignDetailsSlugType
  title?: string
  titleLeftElement?: string
  titleTranslationKey?: string
}

export interface CampaignNavigationRecords {
  [key: string]: CampaignNavigationRecord
}

export interface CustomerInput {
  email: string
  password?: string
  firstName: string
  lastName: string
  profileName: string
}

export interface UpdatePasswordInput {
  password: string
  confirmPassword: string
}

export type SignUpErrorType = 'sign-up-error-email-already-in-use' | 'sign-up-error-general'

export interface RoadmapEntry {
  availability?: ProductAvailability | null | undefined
  nftId: string | null
  headline: string | null
  subHeadline: string | null
  startAt: string | null
  startAtText: string | null
  currentlyActive: boolean
  currentlyAvailableTokens: number | null
  totalAvailableTokens: number | null
  priceInEuros: number | null
  listable: string | null
  purchasable: string | null
  overwriteBulletPointColor?: 'txtDeutschRapPurple' | 'white' | undefined
  productType: ProductType
}

export type UpdatePasswordErrorType =
  | 'update-password-error'
  | 'update-password-error-mismatch-confirm'

export type TokenTransferType = 'single' | 'batch'

export type TransActionStatusType = 'ongoing' | 'failed' | 'success' | null

export interface MigrationToken {
  name: string
  smartContractAddress: string
  amount: number
  tokenId: string
  transactionHash: string | null
  transactionStatus: TransActionStatusType
}

export interface TokenTransferStatus {
  isTransferring: boolean
  status: 'success' | 'error' | 'pending'
  message: string | null
  error: string | null
}

export type OpenPresignedMediaFileHandler = (
  isPrivate: boolean,
  mediaFileUuid: string,
  publicDownloadUrl: string
) => Promise<string | null>

export type DiscordAssetClickHandler = (discordUrl: string) => void

export type ProductAvailability = 'sold-out' | 'buyable' | 'coming-soon'

export interface ProductPurchasbility {
  isPurchasable: boolean
  isListable: boolean
  availability: ProductAvailability
}

export type MediaFileSizeType = 's-thumb' | 's-small' | 's-big'

export type MediaFileMediaType = 'avatar' | 'gallery'

export type AuthenticationStatusType = 'loading' | 'authenticated' | 'unauthenticated'

export type DiscordDeepLinkVisibilityType = 'locked' | 'unlocked' | 'hidden'

export interface PairingResponse {
  result: Response
}

export interface NftProduct {
  code: string
  name: string
}

export interface NftCreator {
  id: string
  name: string
}

export type NftProductWithCreator = {
  product: NftProduct
  creator: NftCreator
}

export type NftProductWithCreatorAvatar = {
  avatar_alt: string
  avatar_src?: string
  bg_image?: string
  link?: string
  date?: string
  headline?: string
  name?: string
}

export interface DiscordOverwrite {
  id: string
  allow: string
}

export interface DiscordChannel {
  id: string
  description?: string
  permissionOverwrites: DiscordOverwrite[]
  name?: string
  productCode?: string
}

export interface DiscordChannelInfo {
  product: NftProduct
  creator: NftCreator
  channel: DiscordChannel
}

export type DiscordUserConnectionStatus =
  | 'DiscordUserIsNotConnectedToTf'
  | 'DiscordUserIsNotOnGuild'
  | 'DiscordUserIsOnGuild'
  | 'notLoggedIn'

//The DiscordRoleAssignmentStatus is the DiscordUserConnectionStatus + the assignment status of a specific role
export type DiscordRoleAssignmentStatus =
  | 'TfCustomerNotPairedWithDiscordUser'
  | 'DiscordUserNotFoundOnGuild'
  | 'DiscordRoleNotAssignedToGuildMember'
  | 'DiscordRoleIsAssignedToGuildMember'

export type DiscordRoleAssignmentRequest = {
  discordRoleId: string
  userId: string
  discordChannelUrl: string
  guildId: string
}

export interface Order {
  '@context': string
  '@id': string
  '@type': string
  uuid: string
  channel: string
  payments: Payment[]
  shipments: any[]
  currencyCode: string
  localeCode: string
  checkoutState: string
  paymentState: string
  shippingState: string
  tokenValue: string
  id: number
  items: any[]
  total: number
  state: string
  taxTotal: number
  shippingTotal: number
  orderPromotionTotal: number
}

export interface Payment {
  '@context': string
  '@id': string
  '@type': string
  id: number
  method: PaymentMethod[]
}

export interface PaymentMethodsResponse {
  '@context': string
  '@id': string
  '@type': string
  'hydra:member': PaymentMethod[]
  'hydra:totalItems': number
}

export interface PaymentMethod {
  '@id': string
  '@type': string
  channels: any[]
  gatewayConfig: any[]
  id: number
  code: string
  environment: any
  position: number
  createdAt: string
  updatedAt: string
  enabled: boolean
  translations: any[]
  translationClass: string
  name: string
  description: any
  instructions: any
  translation: any[]
}

export type ProductType = 'award' | 'ticket' | 'claimOnly' | 'default'

export type Environment = 'development' | 'staging' | 'production'

export type NodeEnvironment = 'development' | 'production'

export type VercelEnvironment = 'development' | 'preview' | 'production'

export type Theme = 'light' | 'dark'

export interface PlayerState {
  playState: PlayState
  currentTrackIndex: number | null
  amountOfTracksInPlaylist: number
}

export type PlayState = 'initial' | 'playing' | 'paused' | 'finished'

export interface NotifyMeAttributes {
  PRODUCT_ID?: string | null
  PRODUCT_NAME?: string | null
}

export interface ProductOwnership {
  ownsProduct: boolean
  ownsProductVariant: boolean
}

export interface ProductAttribute {
  trait_type: string
  value: string
}

export interface TeamMember {
  jobTitle: string
  name: string
  description: string
  avatarImage: string
  hoverImage: string | null
}
export interface ProductSuite {
  id: number
  title1: string
  title2: string
  bgColor: string
  bgImage: string
  bgBlurImage: string
  sectionBgImage: string
  arrowImage: string
  heading: string
  description: string
}

export interface Partner {
  name: string
  description: string
  link: string
}

export interface HomePagePageTheme {
  primaryColor: string
  secondaryColor: string
}

export const HomePageThemes: Record<'yellow' | 'purple' | 'green' | 'default', HomePagePageTheme> =
  {
    yellow: {
      primaryColor: 'txtYellow',
      secondaryColor: 'txtBlack',
    },
    purple: {
      primaryColor: 'txtPurple',
      secondaryColor: 'txtWhite',
    },
    green: {
      primaryColor: 'txtGreen',
      secondaryColor: 'txtBlack',
    },
    default: {
      primaryColor: 'txtBlack',
      secondaryColor: 'txtWhite',
    },
  } as const

export type OpenGraphProperties = {
  locale: 'de' | 'en'
  url: string
  title: string
  type: 'article' | 'website'
  description: string
  site_name: string
  image: {
    alt: string
    type: string
    url: string
    width: string
    height: string
  } | null
  author?: string
  section?: string
  modified_time?: string
  published_time?: string
  card: 'summary' | 'summary_large_image' | 'app' | 'player'
}

export type HomepageNewsletterType = 'default' | 'song-stocks'
