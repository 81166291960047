import { z } from 'zod'

export type ApiResponse<T> =
  | {
      data: T
      error: null
    }
  | { data: null; error: string }

//we can improve this type if we figure out how to make whitelistType required only when isFreeClain == false, maybe with .refine() / .superRefine()
export const claimTokenRequestBodySchema = z.object({
  productId: z.string(),
  isFreeClaim: z.boolean(),
  whitelistType: z.string(),
})

export type ClaimTokenRequestBody = z.infer<typeof claimTokenRequestBodySchema>

export const voucherRedemptionRequestBodySchema = z.object({
  voucherCode: z.string(),
  product: z.any(),
  locale: z.string(),
  accessToken: z.string(),
})

export type VoucherRedemptionRequestBodySchema = z.infer<typeof voucherRedemptionRequestBodySchema>

export const pairDiscordUserRequestBodySchema = z.object({
  tfCustomerUuid: z.string(),
  walletId: z.string(),
  accessToken: z.string(),
})

export type pairDiscordUserRequestBody = z.infer<typeof pairDiscordUserRequestBodySchema>

export const createPresignedUrlSchema = z.object({
  fileName: z.string(),
  s3InputPath: z.string(),
  contentType: z.string(),
})

export type CreatePresignedUrlRequestBody = z.infer<typeof createPresignedUrlSchema>

// For ResponseData type, see type PresignedPost in packages/s3
type Fields = Record<string, string>
export type CreatePresignedUrlResponseData = { fields: Fields; url: string }

export const createEncodingSchema = z.object({
  fileName: z.string(),
  s3InputPath: z.string(),
  s3InputFileUrl: z.string(),
  isPreview: z.boolean(),
})

export type CreateEncodingRequest = z.infer<typeof createEncodingSchema>

export const previewSettingsSchema = z.object({
  previewOffset: z.number().nullable(),
  previewDuration: z.number().nullable(),
})

export type PreviewSettings = z.infer<typeof previewSettingsSchema>

export const encodingInputFileSchema = z.object({
  inputPath: z.string(),
  encodingId: z.string(),
  fileName: z.string(),
  isPreview: z.boolean(),
  previewSettings: previewSettingsSchema,
})

export type EncodingInputFileRequest = z.infer<typeof encodingInputFileSchema>

export const trackEncodingProgressSchema = z.object({
  encodingId: z.string(),
  databaseEncodingId: z.string(),
  isPreview: z.boolean(),
  mainEncodingDatabaseId: z.string().optional(),
})

export type TrackEncodingProgressRequest = z.infer<typeof trackEncodingProgressSchema>
