import NextLink from 'next/link'
import { Link } from '@chakra-ui/react'
import type { FooterSectionEntry } from './footer-section'
import { useTranslation } from 'next-i18next'

interface HomepageFooterEntryProps {
  entry: FooterSectionEntry
  onNewsletterModalOpen?: () => void
}

export const HomepageFooterEntry = ({ entry, onNewsletterModalOpen }: HomepageFooterEntryProps) => {
  const { t } = useTranslation('front-page')

  return (
    <>
      {entry.type === 'newsletterModal' ? (
        <Link onClick={() => onNewsletterModalOpen?.()}>{t(entry.translationKey)}</Link>
      ) : (
        <Link
          as={entry.isDisabled ? 'p' : NextLink}
          variant={entry.isDisabled ? 'comingSoon' : 'default'}
          target={entry.target}
          {...{
            ...(entry.isDisabled ? {} : { href: entry.href }),
          }}
        >
          {t(entry.translationKey)}
        </Link>
      )}
    </>
  )
}
