export type FooterSectionEntry =
  | ({ type: 'link'; href: string; target?: string } & BaseFooterSectionEntry)
  | ({ type: 'newsletterModal' } & BaseFooterSectionEntry)

interface BaseFooterSectionEntry {
  translationKey: string
  isDisabled?: boolean
}

export interface FooterSection {
  title: string
  entries: Array<FooterSectionEntry>
}

const homepageSection: FooterSection = {
  title: 'homepageSection',
  entries: [
    {
      href: '/',
      translationKey: 'home-link',
      type: 'link',
    },
    {
      href: '/advance',
      translationKey: 'song-stocks-link',
      type: 'link',
    },
    {
      href: '/play',
      translationKey: 'digital-vinyl-link',
      type: 'link',
    },
    {
      href: '/play',
      translationKey: 'listening-session-link',
      type: 'link',
    },
    {
      href: '/play',
      translationKey: 'digital-collectible-link',
      type: 'link',
    },
  ],
}

const txtSection: FooterSection = {
  title: 'txtSection',
  entries: [
    {
      href: 'https://marketplace.twlvxtwlv.com',
      translationKey: 'marketplace-link',
      type: 'link',
    },
    {
      href: 'https://twlvxtwlv.com/en/editorials',
      translationKey: 'editorials-link',
      type: 'link',
    },
  ],
}

const aboutSection: FooterSection = {
  title: 'aboutSection',
  entries: [
    {
      translationKey: 'newsletter-link',
      type: 'newsletterModal',
    },
    {
      href: 'mailto:contact@twlvxtwlv.com?subject=Contact via Website',
      translationKey: 'contact-link',
      type: 'link',
    },
    {
      href: 'https://drive.google.com/file/d/1p1V15GNeSKlLeBiR3wx7s7-BxC7JNMHH/view?usp=drive_link',
      translationKey: 'press-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://twelvextwelve.zendesk.com/',
      translationKey: 'faq-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://marketplace.twlvxtwlv.com/en/terms-of-service',
      translationKey: 'terms-and-conditions-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://marketplace.twlvxtwlv.com/privacy-policy',
      translationKey: 'privacy-policy-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://marketplace.twlvxtwlv.com/imprint',
      translationKey: 'imprint-link',
      type: 'link',
      target: '_blank',
    },
  ],
}

const socialsSection: FooterSection = {
  title: 'socialsSection',
  entries: [
    {
      href: 'https://twlvxtwlv.com/r/linkedin/',
      translationKey: 'linkedin-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://twlvxtwlv.com/r/twitter/',
      translationKey: 'twitter-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://twlvxtwlv.com/r/discord/',
      translationKey: 'discord-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://twlvxtwlv.com/r/instagram/',
      translationKey: 'instagram-link',
      type: 'link',
      target: '_blank',
    },
    {
      href: 'https://soundcloud.com/twlvxtwlv',
      translationKey: 'soundcloud-link',
      type: 'link',
      target: '_blank',
    },
  ],
}

export const footerSections = [homepageSection, txtSection, aboutSection, socialsSection]
