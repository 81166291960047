import { IconButton, useDisclosure } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import { Hamburger } from '../../../../shared/icons/hamburger'
import { PageMenuModal } from '../../../../shared/modals/page-menu-modal'
import type { HomePagePageTheme } from '@twlvxtwlv/types'

interface PageMenuButtonProps {
  isHomePage: boolean
  pageTheme: HomePagePageTheme
}

export const PageMenuButton = ({ isHomePage, pageTheme }: PageMenuButtonProps) => {
  const { t } = useTranslation('footer')
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <>
      <IconButton
        aria-label={t('aria-label-play-music-button')}
        height={14}
        width={14}
        fontSize="2em"
        _hover={{ backgroundColor: 'transparent', color: 'txtDarkGray' }}
        bg="transparent"
        variant="edgy"
        icon={<Hamburger />}
        onClick={onOpen}
        color={pageTheme.secondaryColor}
      />
      <PageMenuModal
        isOpen={isOpen}
        onClose={onClose}
        isHomePage={isHomePage}
        bgColor={pageTheme.primaryColor}
        fontColor={pageTheme.secondaryColor}
      />
    </>
  )
}
