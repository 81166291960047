import { Flex, useDisclosure, useToast } from '@chakra-ui/react'
import { footerSections } from './footer-section'
import { HomepageFooterSection } from './homepage-footer-section'
import { FooterCopyrightBar } from './footer-copyright-bar'
import { HomepageNewsletterModal } from './homepage-newsletter-modal'
import { useTranslation } from 'next-i18next'
import type { HomepageNewsletterType, HomePagePageTheme } from '@twlvxtwlv/types'
import { serverSideTrackEvent } from '@twlvxtwlv/analytics/src/browser'
import { initDistinctId } from '@twlvxtwlv/utils/src/cookie'

interface HomepageFooterProps {
  pageTheme: HomePagePageTheme
  newsletterType: HomepageNewsletterType
}

export const HomepageFooter = ({ pageTheme, newsletterType }: HomepageFooterProps) => {
  const { t } = useTranslation('common')

  const distinctId = initDistinctId()

  const {
    isOpen: isNewsletterModalOpen,
    onClose: onCloseNewsletterModal,
    onOpen: onOpenNewsletterModal,
  } = useDisclosure()

  const toast = useToast()

  const onNewsletterSignupSuccess = (newsletterId: number) => {
    onCloseNewsletterModal()

    toast({
      position: 'top-right',
      status: 'success',
      title: t('common:newsletter-signup-success-toast-title'),
    })

    serverSideTrackEvent(
      { userId: distinctId },
      {
        eventName: 'homepage-footer-newsletter-signup-success',
        newsletterId: newsletterId,
      }
    )
  }

  const onNewsletterSignupError = () => {
    onCloseNewsletterModal()

    toast({
      position: 'top-right',
      status: 'error',
      title: t('common:newsletter-signup-failed-toast-title'),
    })
  }

  return (
    <Flex as="footer" w="full" h="full" direction="column">
      <Flex
        h="70%"
        bg="txtBlack"
        w="full"
        justifyContent={['center', 'center', 'center', 'space-around', 'space-around']}
        direction={['column', 'column', 'column', 'row', 'row']}
        borderBottom="1px solid white"
      >
        {footerSections.map((section, index) => (
          <HomepageFooterSection
            key={section.title}
            section={section}
            isLastSection={index + 1 === footerSections.length}
            onNewsletterModalOpen={onOpenNewsletterModal}
          />
        ))}
      </Flex>
      <Flex h="30%" w="full">
        <FooterCopyrightBar pageTheme={pageTheme} />
      </Flex>
      <HomepageNewsletterModal
        isOpen={isNewsletterModalOpen}
        onClose={onCloseNewsletterModal}
        onSuccess={onNewsletterSignupSuccess}
        onError={onNewsletterSignupError}
        newsletterType={newsletterType}
      />
    </Flex>
  )
}
