import { Box, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import type { HomePagePageTheme } from '@twlvxtwlv/types'

export interface FooterCopyrightBarProps {
  pageTheme: HomePagePageTheme
}

export const FooterCopyrightBar = ({ pageTheme }: FooterCopyrightBarProps) => {
  const { t } = useTranslation('front-page')
  return (
    <Box bgColor={pageTheme.primaryColor} w="full" h="full" p="1rem">
      <Text fontSize="sm" color={pageTheme.secondaryColor}>
        {t('copyright')}
      </Text>
    </Box>
  )
}
