import { Box, Button, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import NextLink from 'next/link'
import { useState } from 'react'
import { PageMenuButton } from './page-menu-button'
import type { HomePagePageTheme } from '@twlvxtwlv/types'

type NavigationProps = {
  isHomePage?: boolean
  pageTheme: HomePagePageTheme
}

export const Navigation = ({ isHomePage = false, pageTheme }: NavigationProps) => {
  const { t } = useTranslation(['navigation', 'nft-details', 'common'])

  const [imagePathLeft, setImagePathLeft] = useState('/txt-logo.webp')

  const ctaText = useBreakpointValue({
    base: t('calculator-book-translation-btn'),
    md: t('calculator-book-translation-btn'),
  })

  return (
    <>
      <Flex
        id="nav"
        direction="row"
        w="100%"
        h={14}
        justifyContent="space-between"
        gap={8}
        backgroundColor={pageTheme.primaryColor}
        position="sticky"
        alignItems="center"
      >
        <Box minW={14} zIndex={2}>
          <Box>
            <IconButton
              as={NextLink}
              aria-label={t('aria-label-txt-homepage')}
              variant="ghost"
              rounded="none"
              w={14}
              h={14}
              minW={14}
              bg="txtBlack"
              borderRadius={0}
              _hover={{
                background: 'txtBlack',
              }}
              icon={
                <Image
                  onMouseEnter={() =>
                    setImagePathLeft('/images/twelve-logo-txt-circle-white-spinning.gif')
                  }
                  onMouseLeave={() => setImagePathLeft('/txt-logo.webp')}
                  src={imagePathLeft}
                  alt="txt-logo-white-gif"
                  fill
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              }
              href="https://twlvxtwlv.com/"
              passHref
            />
          </Box>
        </Box>

        <Flex
          alignItems="center"
          justifyContent="center"
          justifySelf="center"
          position="absolute"
          left="50%"
          transform="translateX(-50%)"
          zIndex={2}
        >
          <PageMenuButton isHomePage={isHomePage} pageTheme={pageTheme} />
        </Flex>

        <Box right="0" zIndex={2} mr={{ base: 2, md: 4 }}>
          <Button
            as={NextLink}
            href="https://outlook.office365.com/book/twelvextwelveAdvance@twlvxtwlv.com/"
            target="_blank"
            variant="txtWhite"
            fontSize={{ base: '2xs', md: 'md' }}
          >
            {ctaText}
          </Button>
        </Box>
      </Flex>
    </>
  )
}
